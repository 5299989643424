import React from 'react'
import { format } from 'date-fns'
import { useSpring, animated } from 'react-spring'

import Layout from '../components/layout'
import StaticMap from '../images/static-map.png'

const HeroSection = () => {
  const today = format(new Date(), 'iiii').toLowerCase()

  const springOpacity = useSpring({
    from: { opacity: 0, transform: 'translateX(90px)' },
    to: { opacity: 1, transform: 'translateX(0)' },
  })
  return (
    <animated.div
      style={springOpacity}
      className="relative bg-primary-600 overflow-hidden"
    >
      <div className="max-w-screen-xl mx-auto">
        <div className="relative z-10 pb-8 bg-primary-600 sm:pb-16 md:pb-20 lg:max-w-2xl lg:w-full lg:pb-28 xl:pb-32">
          <div className="pt-6 px-4 sm:px-6 lg:px-8"></div>

          <div className="absolute top-0 inset-x-0 p-2 transition transform origin-top-right md:hidden">
            <div className="rounded-lg shadow-md">
              <div className="rounded-lg bg-primary-600 shadow-xs overflow-hidden"></div>
            </div>
          </div>

          <div className="mt-4 mx-auto max-w-screen-xl px-4 sm:mt-12 sm:px-6 md:mt-8 lg:mt-8 lg:px-8 xl:mt-12">
            <div className="sm:text-center lg:text-left">
              <h2 className="text-4xl tracking-tight leading-10 font-extrabold text-white sm:text-5xl sm:leading-none md:text-6xl">
                Cortiz Fitness <br className="xl:hidden" />
                <span className="text-accent-400">Gym Location</span>
              </h2>
              <p className="mt-3 text-base text-gray-200 sm:mt-5 sm:text-lg sm:max-w-xl sm:mx-auto md:mt-5 md:text-xl lg:mx-0">
                6897 W Colonial Dr, Orlando, FL 32818
              </p>
              <div className="flex xs:max-w-xl xs:mx-auto sm:justify-center lg:justify-start lg:mx-0">
                <div className="flex flex-col">
                  <span className="text-medium leading-5 text-gray-200">
                    Located in:
                  </span>
                  <span
                    className={`leading-5 pt-2 ${
                      today === 'monday'
                        ? 'text-md text-white'
                        : 'text-sm text-gray-400'
                    }`}
                  >
                    Mondays:
                  </span>
                  <span
                    className={`leading-5 ${
                      today === 'tuesday'
                        ? 'text-md text-white'
                        : 'text-sm text-gray-400'
                    }`}
                  >
                    Tuesdays:
                  </span>
                  <span
                    className={`leading-5 ${
                      today === 'wednesday'
                        ? 'text-md text-white'
                        : 'text-sm text-gray-400'
                    }`}
                  >
                    Wednesdays:
                  </span>
                  <span
                    className={`leading-5 ${
                      today === 'thursday'
                        ? 'text-md text-white'
                        : 'text-sm text-gray-400'
                    }`}
                  >
                    Thursdays:
                  </span>
                  <span
                    className={`leading-5 ${
                      today === 'friday'
                        ? 'text-md text-white'
                        : 'text-sm text-gray-400'
                    }`}
                  >
                    Fridays:
                  </span>
                  <span
                    className={`leading-5 ${
                      today === 'saturday'
                        ? 'text-md text-white'
                        : 'text-sm text-gray-400'
                    }`}
                  >
                    Saturdays:
                  </span>
                  <span
                    className={`leading-5 ${
                      today === 'sunday'
                        ? 'text-md text-white'
                        : 'text-sm text-gray-400'
                    }`}
                  >
                    Sundays:
                  </span>
                </div>
                <div className="ml-3">
                  <div className="flex flex-col">
                    <span className="text-medium leading-5 text-gray-200">
                      Hiawassee Plaza
                    </span>
                    <span
                      className={`leading-5 pt-2 ${
                        today === 'monday'
                          ? 'text-md text-white'
                          : 'text-sm text-gray-400'
                      }`}
                    >
                      7AM–8PM
                    </span>
                    <span
                      className={`leading-5 ${
                        today === 'tuesday'
                          ? 'text-md text-white'
                          : 'text-sm text-gray-400'
                      }`}
                    >
                      7AM–8PM
                    </span>
                    <span
                      className={`leading-5 ${
                        today === 'wednesday'
                          ? 'text-md text-white'
                          : 'text-sm text-gray-400'
                      }`}
                    >
                      7AM–8PM
                    </span>
                    <span
                      className={`leading-5 ${
                        today === 'thursday'
                          ? 'text-md text-white'
                          : 'text-sm text-gray-400'
                      }`}
                    >
                      7AM–8PM
                    </span>
                    <span
                      className={`leading-5 ${
                        today === 'friday'
                          ? 'text-md text-white'
                          : 'text-sm text-gray-400'
                      }`}
                    >
                      7AM–8PM
                    </span>
                    <span
                      className={`leading-5 ${
                        today === 'saturday'
                          ? 'text-md text-white'
                          : 'text-sm text-gray-400'
                      }`}
                    >
                      7AM–1PM
                    </span>
                    <span
                      className={`leading-5 ${
                        today === 'sunday'
                          ? 'text-md text-white'
                          : 'text-sm text-gray-400'
                      }`}
                    >
                      closed
                    </span>
                  </div>
                </div>
              </div>
              <div className="mt-5 sm:mt-8 sm:flex sm:justify-center lg:justify-start">
                <div className="rounded-md shadow">
                  <a
                    href="https://maps.app.goo.gl/tb4JPw71SDQyUGoJA"
                    className="w-full flex items-center justify-center px-8 py-3 border border-transparent text-base leading-6 font-medium rounded-md text-white bg-accent-400 hover:bg-accent-500 focus:outline-none focus:shadow-outline transition duration-150 ease-in-out md:py-4 md:text-lg md:px-10"
                  >
                    Get directions
                  </a>
                </div>
              </div>
            </div>
          </div>
          <svg
            className="hidden lg:block absolute right-0 inset-y-0 h-full w-48 text-white transform translate-x-1/2"
            fill="#007785"
            viewBox="0 0 100 100"
            preserveAspectRatio="none"
          >
            <polygon points="50,0 100,0 50,100 0,100" />
          </svg>
        </div>
      </div>
      <div className="lg:absolute lg:inset-y-0 lg:right-0 lg:w-1/2">
        <img
          className="h-56 w-full object-cover sm:h-72 md:h-96 lg:w-full lg:h-full"
          src={StaticMap}
          alt="cortiz fitness gym location"
        />
      </div>
    </animated.div>
  )
}

const GymLocation = () => {
  return (
    <Layout seoTitle="Cortiz Fitness Location">
      <HeroSection />
    </Layout>
  )
}

export default GymLocation
